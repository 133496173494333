import axiosInstance from "@/store/axios.instance";
import routeNames from "@/router/RouteNames";
import router from "@/router";
import getUserLocale from 'get-user-locale';
import {getTimeZone} from "@/shared/utils/dateUtils"


export default {
    namespaced: true,
    state() {
        return {
            user: null,
            userLocale: getUserLocale({fallbackLocale: "nl-be"})
        }
    },
    mutations: {
        handleLoginResult(state, result) {
            const user = result?.data
            if (user.token) {
                localStorage.setItem('token', user.token)
                state.user = user
            }
        },
        setUser(state, user) {
            state.user = user
        },
        setUserName(state, data) {            
            if(state.user.id === data.userId){
            state.user.firstName = data.userName.firstName
            state.user.lastName = data.userName.lastName
            }
        },
    },
    actions: {
        async updateOwnPassword({commit, rootGetters}, passwordRequest) {
            await axiosInstance.put('/password/update-own', passwordRequest, rootGetters['authModule/getUrlConfig'])
                .then(result => commit('usersModule/insertUser', result?.data, {root: true}))
                .catch(function (error) {
                    return Promise.reject(error);
                });
        },
        async updateOwnPasswordToken({commit, rootGetters}, passwordRequest) {
            await axiosInstance.post('/password/update-own-token', passwordRequest, rootGetters['authModule/getUrlConfig'])
                .then(async result => {
                    commit('handleLoginResult', result)
                    await router.push({name: routeNames.ROOT})
                })
                .catch(function (error) {
                    return Promise.reject(error);
                });
        },
        async validatePasswordResetToken({rootGetters}, passwordRequest) {
            return await axiosInstance.post('/password/validate-token', passwordRequest, rootGetters['authModule/getUrlConfig'])
        },
        async updateOtherPassword({commit, rootGetters}, passwordRequest) {
            axiosInstance.put('/password/update-other', passwordRequest, rootGetters['authModule/getUrlConfig'])
                .then(result => commit('usersModule/insertUser', result?.data, {root: true}))
        },
        forgotPassword({rootGetters}, resetRequest) {
            return axiosInstance.post('/password/forgot', resetRequest, rootGetters['authModule/getUrlConfig'])
        },
        login({commit}, user) {
            return axiosInstance.post('/users/login', user)
                .then(async result => {
                    commit('handleLoginResult', result)
                    await router.push({name: routeNames.ROOT})
                })
                .catch(async error => {
                    if (router.currentRoute.name !== routeNames.LOGIN) {
                        await router.push({name: routeNames.LOGIN})
                    }
                    return Promise.reject(error)
                })
        },
        loginWithToken({commit}, token) {
            return axiosInstance.get('users/token', {
                headers: {
                    Authorization: 'Bearer ' + token
                }
            })
                .then(result => {
                    commit('handleLoginResult', result)
                    return result
                })
                .catch(async error => {
                    // remove token if login fails
                    localStorage.removeItem('token')
                    await Promise.reject(error)
                })
        },
        // Creates Org with admin user and a Trial subscription
        // return: null
        async registerTrial({commit, rootGetters}, registrationRequest) {
            return await axiosInstance.post('organization/register', registrationRequest, rootGetters['authModule/getUrlConfig'])
                .then(result => commit('handleLoginResult', result))
                .catch(function (error) {
                    return Promise.reject(error);
                });
        },
        // Creates payment request, stores org and admin user to be created in our DB
        // return: Payment GW redirect URL
        async initiatePayment_withOrganizationAndAdminUser({rootGetters}, registrationRequest) {
            return await axiosInstance.post('payment/create-organization', registrationRequest, rootGetters['authModule/getUrlConfig'])
                .then(result => {
                    return result?.data;
                })
                .catch(function (error) {
                    return Promise.reject(error);
                });
        },
    },
    getters: {
        isAdmin(state) {
            if (state.user) {
                return state.user.roles.includes("ADMIN")
            } else {
                return false
            }
        },
        isLeader(state) {
            return !!state.user?.roles.includes("LEADER")
        },
        getUrlConfig(state) {
            let config = {
                headers: {
                    timeZone: getTimeZone()
                }
            }
            if (state.user && state.user.token) {
                config.headers.Authorization = "Bearer " + state.user.token
                if (state.user?.organizationId) {
                    config.headers.Organization = state.user?.organizationId
                }
            }
            return config
        },
        getOrganizationId(state) {
            return state.user ? state.user.organizationId : null
        },
        getUserId(state) {
          return state.user ? state.user.id : null
        },
        getUserLocale(state) {
            return state.userLocale
        }
    },
}